<template>
  <div class="col-12" v-cloak @click="menu = false">
    <!-- overlay div -->
    <div class="team-overlay"></div>
    <!-- Alert to show user why his Reports tab is disable -->
    <div>
      <v-alert
        v-if="active_team_members_list.length <= 1"
        class="ma-0"
        border="left"
        colored-border
        color="#304FFE"
      >
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Please invite your Team Members, you don't have any members yet.
</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToTeamMembers"
        >
          <div class="mt">Invite new members</div>
        </div>
      </v-alert>
      <v-alert v-else class="ma-0" border="left" colored-border color="#304FFE">
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Sorry you don't have any stats yet, Please ask your team members to
          download the Desktop App.</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToDownloadApp"
        >
          <div class="mt">Download App</div>
        </div>
      </v-alert>
    </div>
    <!-- Reports dummy page -->
    <div class="nill-opacity mt-5">
      <!-- Header -->
      <div class="d-flex flex-wrap align-content-center">
        <div class="bold ml-1 mt-6">
          <div class="dummy-export">Export As</div>
        </div>
        <div class="ml-3 bold">
          <label class="mr-16 ml-n2">Date</label>
          <div class="dummy-input">
            Yesterday
            <v-icon class="float-right mr-2">mdi-calendar-range</v-icon>
          </div>
        </div>
        <div class="bold ml-3">
          <label class="mr-6">Team Members</label>
          <div class="dummy-input">All Team Members</div>
        </div>
        <div class="bold ml-3">
          <label class="mr-6">Projects</label>
          <div class="dummy-input">All Projects</div>
        </div>
        <div class="bold ml-3">
          <label class="mr-6">Departments</label>
          <div class="dummy-input">All Departments</div>
        </div>
        <div
          class="search-btn mt-7 ml-5 d-flex align-content-center justify-content-center"
          color="#304FFE"
        >
          <div class="ml-10">
            <v-icon color="grey" class="mr-3">mdi-find-replace</v-icon>Search
          </div>
        </div>
      </div>
      <!-- Reports detail card -->
      <div class="mt-5">
        <div class="m-4">
          <div class="d-flex">
            <v-icon>mdi-minus-circle-outline</v-icon>
            <h5 class="ml-2 mt-2">App project 1</h5>
            <v-spacer></v-spacer>
            <h5 class="float-right mr-5 mt-2">
              {{ projectTime }}
            </h5>
          </div>
          <v-divider></v-divider>
          <div
            class="d-flex ml-10 mt-10"
            v-for="member in members"
            :key="member.id"
          >
            <v-icon style="z-index: -0.3 !important"
              >mdi-plus-circle-outline</v-icon
            >
            <img
              style="z-index: -0.3 !important"
              class="dummy-img-size ml-2"
              src="../../assets/images/no-member.svg"
              alt=""
            />
            <h5 class="ml-2 mt-2">{{ member }}</h5>
            <v-spacer></v-spacer>
            <h5 class="float-right mr-5 mt-2">
              {{ employeeTime }}
            </h5>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
export default {
  name: "firstWorkDiarys",
  data() {
    return {
      members: [
        "John Hopkins",
        "Shakespeare",
        "CalArts",
        "Andrew Walter",
        "Jessica",
      ],
      projectTime: "52:23",
      employeeTime: "14:64",
    };
  },
  computed: {
    ...mapState("custom", ["active_team_members_list"]),
  },
  methods: {
    //   Route to team members page
    routeToTeamMembers() {
      this.$router.push({
        name: "TeamMembers",
        query: {
          new: "yes",
        },
      });
    },
    routeToDownloadApp() {
      this.$router.push({
        name: "DownloadApp",
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.mt {
  margin-top: 5px !important;
}
.promt-btn {
  width: 175px !important;
  border-radius: 5px;
  height: 35px;
  text-align: center;
  vertical-align: center;
  background-color: #304ffe;
  z-index: 1 !important;
  animation-name: bounce-2 !important;
  animation-timing-function: ease !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.team-overlay {
  position: fixed;
  z-index: 0.9;
  background: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
}
.search-btn {
  background-color: #304ffe;
  width: 200px;
  height: 32px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
}
.dummy-input {
  width: 180px;
  background-color: white;
  border: 1px solid rgb(202, 217, 223);
  margin-top: 5px;
  padding: 3px 0px 3px 5px;
  border-radius: 2px;
  font-weight: 300 !important;
}
.dummy-export {
  width: 150px;
  background-color: white;
  border: 1px solid rgb(202, 217, 223);
  margin-top: 5px;
  padding: 3px 0px 3px 5px;
  border-radius: 2px;
  font-weight: 300 !important;
}
.bold {
  font-weight: 600;
}
.screenshots-data {
  height: 120px;
  width: 115px;
  background-color: #f1f6ff;
  border: 1px solid rgb(216, 231, 231);
}
.dummy-img-size {
  height: 50px;
  width: 50px;
  z-index: -0.3 !important;
}
</style>
